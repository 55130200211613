import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2962ff',
    },
    secondary: {
      main: '#00e676',
    },
    background: {
      default: '#0a192f',
      paper: 'rgba(255, 255, 255, 0.05)',
    },
    text: {
      primary: '#ffffff',
      secondary: '#8892b0',
    },
  },
  typography: {
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.5px',
      textAlign: 'center',
    },
    h4: {
      fontWeight: 600,
      textAlign: 'center',
    },
    h5: {
      textAlign: 'center',
    },
    body1: {
      textAlign: 'center',
    }
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        flexGrow: 1, 
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
        backgroundImage: 'radial-gradient(circle at 10% 20%, rgba(41, 98, 255, 0.1) 0%, transparent 50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Container maxWidth="md" sx={{ 
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Box sx={{ 
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
            <img 
              src="/exla-logo-no-bg.png" 
              alt="Exla Logo" 
              style={{ 
                width: '30%',
                height: 'auto',
                maxHeight: 300,
                objectFit: 'contain',
              }}
            />
          </Box>
          {/* Header */}
          <Box sx={{ 
            textAlign: 'center', 
            py: 6,
            mb: 4,
            width: '100%'
          }}>
            <Typography variant="h2" 
              sx={{
                background: 'linear-gradient(45deg, #2962ff, #00e676)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2,
                textAlign: 'center'
              }}
            >
              Large Models with Blazing Speed on Edge Devices
            </Typography>
            <Typography variant="h5" color="text.secondary" sx={{ textAlign: 'center' }}>
              
            </Typography>
          </Box>

          {/* Contact Section */}
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8, 
            mb: 4,
            p: 3, 
            borderRadius: 2,
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Interested in learning more? Reach out to us at{' '}
              <Link 
                href="mailto:founders@exla.ai"
                sx={{ 
                  color: '#00e676',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    opacity: 0.8
                  }
                }}
              >
                founders@exla.ai
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
